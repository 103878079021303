import React, { useEffect, useRef, useState } from 'react'

import { path } from '@/core/utils'
import { useApp } from '@/core/contexts/app'

import Image from '@/components/base/image/Image'
import Video from '@/components/base/video/Video'

import { classnames } from 'begonya/functions'

import { UlupinarVideoBannerProps } from './type'
import { useMobile } from '@/core/hooks'
import Icon from '@/components/base/icon'
import Button from '@/components/base/button/Button'

const UlupinarVideoBanner: React.FC<UlupinarVideoBannerProps> = ({
  video,
  image,
}) => {
  const app = useApp()
  const isMobile = useMobile(app.headers['user-agent'])
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const [playButton, setPlayButton] = useState(true)
  const [loaded, setLoaded] = useState(false)

  // useEffect(() => {
  //   if (video && videoRef.current) {
  //     videoRef.current.src = path.asset(app.environment, video.src)
  //     videoRef.current.play()
  //     videoRef.current.addEventListener('playing', () => {
  //       setLoaded(true)
  //     })
  //   } else {
  //     setLoaded(false)
  //   }
  // }, [])

  const videoSettings = {
    autoPlay: false,
    controls: false,
    loop: true,
    playsInline: true,
    preload: 'auto',
  }

  const handlePlay = () => {
    if (videoRef.current) {
      videoRef.current.play()
    }
    setPlayButton(false)
    setLoaded(true)
  }

  return (
    <div className="ulupinar-video-banner">
      <div className="ulupinar-video-banner-body">
        {image && (
          <div
            className={classnames(
              'ulupinar-video-banner-body-image',
              loaded && 'hidden'
            )}
          >
            <Image {...image} alt={app.page.title} />
          </div>
        )}
        <div
          className={classnames(
            'ulupinar-video-banner-body-video',
            loaded ? '' : 'ulupinar-video-banner-body-video-hidden'
          )}
        >
          {video && (
            <Video
              ref={videoRef}
              {...videoSettings}
              src={path.asset(app.environment, video.src)}
            />
          )}
        </div>
        <Button
          className={classnames(
            'ulupinar-video-banner-body-button',
            playButton ? '' : 'ulupinar-video-banner-body-button--close'
          )}
          onClick={() => handlePlay()}
        >
          <Icon name="play-arrow" />
        </Button>
      </div>
    </div>
  )
}

export default UlupinarVideoBanner
